<script setup>
import { ref, onMounted } from 'vue'

const props = defineProps({
  eventId: { type: Number, required: true }
})

const errors = ref(null);
const isLoading = ref(true);
const source = ref('');
const videoDiv = ref(null);

onMounted(() => {
  fetch(`/streams/${props.eventId}`)
    .then(response => response.json())
    .then(json => {
      if (json.errors) {
        errors.value = json.errors.map(error => error.message).join('\n');
        return;
      }
      source.value = json.path;
      videoDiv.value.load();
      videoDiv.value.onloadeddata = () => {
        videoDiv.value.play();
      }
      isLoading.value = false;
    })
    .catch(error => {
      console.error(error);
      // Gestione dell'errore qui se necessario
    })
})

</script>

<template>
  <div class="card">
    <div class="card-body text-center">
      <div v-show="isLoading">
        Caricamento in corso...
      </div>
      <div v-if="errors" class="alert alert-danger mb-0" role="alert">
        {{ errors }}
      </div>
      <video v-show="!isLoading"
             ref="videoDiv"
             height="300"
             controls
             loop
             style="min-width: 100%;">
        <source :src="source" type="video/webm">
      </video>
    </div>
  </div>
</template>