console.log('Vite ⚡️ Rails')

import { Turbo } from "@hotwired/turbo-rails"
import * as bootstrap from "bootstrap"
import '../style/application.sass'
import { createApp } from 'vue'
import '../controllers'

import DrawBox from '../components/DrawBox.vue'
import VideoStream from '../components/VideoStream.vue'

document.addEventListener('turbo:load', () => {
  initDrawBox()
})

document.addEventListener('turbo:frame-load', () => {
  initDrawBox()
})

document.addEventListener("turbo:render", function () {
  initDrawBox()
})

function initDrawBox() {
  const node = document.querySelector('#draw-box')
  if(node == null) return
  const areaX = parseInt(node.dataset.areaX)
  const areaY = parseInt(node.dataset.areaY)
  const areaW = parseInt(node.dataset.areaW)
  const areaH = parseInt(node.dataset.areaH)
  const sensorId = parseInt(node.dataset.sensorId)
  const modelName = node.dataset.modelName
  const app = createApp(DrawBox, {
    areaX: areaX,
    areaY: areaY,
    areaW: areaW,
    areaH: areaH,
    sensorId: sensorId,
    modelName: modelName,
  })

  app.mount('#draw-box')
}

if (navigator.serviceWorker) {
  navigator.serviceWorker
           .register("/service-worker.js")
           .then(() => navigator.serviceWorker.ready)
           .then((registration) => {
             console.log("Service worker registrato!", registration);
           })
           .then(() => console.log("[Companion]", "Service worker registered!"));
}

document.addEventListener('turbo:load', () => {
  const nodeStr = 'vue-video-stream'
  const node = document.getElementById(nodeStr)
  // se il nodo esiste
  if(node != null) {
    const eventId = parseInt(node.dataset.eventId)
    console.log(node.dataset)
    if (eventId == null || isNaN(eventId)) {
      console.error('ATTENZIONE! vue-video-stream: data-event-id is required')
      return
    }
    const app = createApp(VideoStream, {
      eventId: eventId,
    })
    app.mount(`#${nodeStr}`)
  }
})
