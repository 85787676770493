let interval = null;
export default function setupNotification(){
  Notification.requestPermission().then((result) => {
    // se il permesso è stato dato mandare una notifica
    if (result === "granted") {
      console.log("è granted")
      //notifyExample(registration);
      if (interval !== null) {
        clearInterval(interval);
      }
      interval = setInterval(askNotification, 5000);
    } else {
      // qui probabilmente dobbiamo obbligare l'utente ad accettare le notifiche con una schermata che gli obblighi a farlo
      console.log("non è granted")
      if (window.location.pathname !== "/enable_notification") {
        location.href = "/enable_notification";
      }
    }
  });
}


// do a fetch to ask if there is notification to send
function askNotification() {
  fetch("/notifications")
    .then((response) => {
      return response.json()
    })
    .then((data) => {
      const events = data.events;
      events.forEach((event) => {
        if (event) {
          // title: "Allarme per caduta"
          const title = event.title;
          const installation = event.installation_id
          const user_installation = event.current_user_installation_id
          const options = {
            // "L'utente è caduto alle 12:00"
            body: event.body,
            // "pwa-icon.png"
            icon: event.icon
          };
          if(user_installation == installation){
            console.log("ciao")
            playSound();
            navigator.serviceWorker.ready.then((registration) => {
              registration.showNotification(title, options);
            });
          }
        }
      })
    });
}

function playSound() {
  const audio = new Audio("/beep.wav");
  audio.play();
}
