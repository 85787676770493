import { Controller } from "@hotwired/stimulus"
import setupNotification from "../setup_notification"

export default class extends Controller {
  connect() {
    console.log("Setup notification in corso...")
    setupNotification()
  }
  disconnect() {
    console.log("disconnect...")
  }
}
