<script setup>
  import { ref, reactive, onMounted } from 'vue'
  const props = defineProps({
    areaX: { type: Number, required: true },
    areaY: { type: Number, required: true },
    areaW: { type: Number, required: true },
    areaH: { type: Number, required: true },
    sensorId: { type: Number, required: true },
    modelName: { type: String, required: true }
  })
  const mouseX = ref(0);
  const mouseY = ref(0);
  const maxImgWidth = 636;
  const maxImgHeight = 453;
  const roomImage = ref(null)
  const drawingCanvas = ref(null)
  const rectWidth = ref(props.areaW);
  const rectHeight = ref(props.areaH);
  const modelName = ref(props.modelName);
  const isDrawing = ref(false);
  const startPos = reactive({ x: ref(props.areaX), y: ref(props.areaY) });
  const endPos = reactive({ x: 0, y: 0 });
  const canvasWidth = ref(0);
  const canvasHeight = ref(0);

  const imageUrl = '/sensors/snapshot-' + props.sensorId + '.jpg'

  const handleMouseDown = () => {
    console.log('mouse down');
    isDrawing.value = true;
    startPos.x = mouseX.value;
    startPos.y = mouseY.value;
  };

  const handleMouseUp = () => {
    console.log('mouse up');
    isDrawing.value = false;
    endPos.x = mouseX.value;
    endPos.y = mouseY.value;
    if (startPos && endPos) {
      // Draw the rectangle using startPos and endPos
      rectWidth.value = endPos.x - startPos.x
      rectHeight.value = endPos.y - startPos.y
      drawRect(startPos.x, startPos.y, rectWidth.value, rectHeight.value);
      // Resize coords for db
      startPos.x = Math.round((startPos.x/canvasWidth.value) * maxImgWidth)
      startPos.y = Math.round((startPos.y/canvasHeight.value) * maxImgHeight)
      endPos.x = Math.round((endPos.x/canvasWidth.value) * maxImgWidth)
      endPos.y = Math.round((endPos.y/canvasHeight.value) * maxImgHeight)
      if (startPos.x > endPos.x) {
        let temp = endPos.x
        endPos.x = startPos.x
        startPos.x = temp
      }
      if (startPos.y > endPos.y) {
        let temp = endPos.y
        endPos.y = startPos.y
        startPos.y = temp
      }
      rectWidth.value = endPos.x - startPos.x
      rectHeight.value = endPos.y - startPos.y
    }
  };

  const handleMouseMove = (event) => {
    const rect = event.target.getBoundingClientRect();
    mouseX.value = event.clientX - rect.left;
    mouseY.value = event.clientY - rect.top;
    if (isDrawing.value) {
      endPos.x = mouseX.value
      endPos.y = mouseY.value
      // Draw the rectangle
      rectWidth.value = mouseX.value - startPos.x
      rectHeight.value = mouseY.value - startPos.y
      drawRect(startPos.x, startPos.y, rectWidth.value, rectHeight.value);
    }
  };

  const handleResize = () => {
    if (roomImage) {
      canvasWidth.value = roomImage.value.clientWidth;
      canvasHeight.value = roomImage.value.clientHeight;
      if (startPos && endPos) {
        const coordX = (startPos.x/maxImgWidth) * canvasWidth.value
        const coordY = (startPos.y/maxImgHeight) * canvasHeight.value
        const areaWidth = (rectWidth.value/maxImgWidth) * canvasWidth.value
        const areaHeight = (rectHeight.value/maxImgHeight) * canvasHeight.value
        setTimeout(() => { drawRect(coordX, coordY, areaWidth, areaHeight) }, 200);
      }
    }
  };

  const drawRect = (coordX, coordY, areaWidth, areaHeight) => {
    const ctx = drawingCanvas.value.getContext("2d");
    ctx.clearRect(0, 0, drawingCanvas.value.width, drawingCanvas.value.height);
    ctx.beginPath();
    ctx.rect(coordX, coordY, areaWidth, areaHeight);
    ctx.stroke();
  };

  onMounted(() => {
    roomImage.value.onload = function (){
      handleResize()
      window.addEventListener('resize', handleResize)
      const coordX = (props.areaX/maxImgWidth) * canvasWidth.value
      const coordY = (props.areaY/maxImgHeight) * canvasHeight.value
      const areaWidth = (props.areaW/maxImgWidth) * canvasWidth.value
      const areaHeight = (props.areaH/maxImgHeight) * canvasHeight.value
      setTimeout(() => { drawRect(coordX, coordY, areaWidth, areaHeight) }, 500);
    }
  });

</script>

<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <div class="canvas-container"
             @mousemove="handleMouseMove"
             @mousedown="handleMouseDown"
             @mouseup="handleMouseUp">
          <img :src="imageUrl"
               alt="room-example"
               class="img-fluid"
               style="pointer-events: none;"
               id="room-image"
               ref="roomImage"
          />
          <canvas ref="drawingCanvas"
                  id="drawing-canvas"
                  :width="canvasWidth"
                  :height="canvasHeight"
                  @mousedown="handleCanvasMouseDown"
                  @mouseup="handleCanvasMouseUp"
                  @mousemove="handleCanvasMouseMove"
          ></canvas>
        </div>
      </div>
      <div class="col-md-6" style="visibility: hidden;">
        <div class="row">
          <div class="col-md-6">
            <label class="form-label" :for="`${props.modelName}_area_x`">Area X</label>
            <input type="number" :name="`${props.modelName}[area_x]`" :id="`${props.modelName}_area_x`" class="form-control mb-3" min="0" :value="Math.round(startPos.x)">
          </div>
          <div class="col-md-6">
            <label class="form-label" :for="`${props.modelName}_area_y`">Area Y</label>
            <input type="number" :name="`${props.modelName}[area_y]`" :id="`${props.modelName}_area_y`" class="form-control mb-3" min="0" :value="Math.round(startPos.y)">
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label class="form-label" :for="`${props.modelName}_area_w`">Area Width</label>
            <input type="number" :name="`${props.modelName}[area_w]`" :id="`${props.modelName}_area_w`" class="form-control mb-3" min="0" :value="rectWidth">
          </div>
          <div class="col-md-6">
            <label class="form-label" :for="`${props.modelName}_area_h`">Area Height</label>
            <input type="number" :name="`${props.modelName}[area_h]`" :id="`${props.modelName}_area_h`" class="form-control mb-3" min="0" :value="rectHeight">
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<style scoped>
.canvas-container {
  position: relative;
  display: inline-block;
}

#drawing-canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1; /* Set z-index to make the canvas appear on top */
}
</style>